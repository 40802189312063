import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic01 from '../assets/images/expertise.jpg'

const services = (props) => (
    <Layout>
        <div>
            <Helmet>
                <title>Expertise | Jonathan Agoot</title>
                <meta name="description" content="Expertise" />
            </Helmet>
            <div id="main">
                <section id="banner" className="style2">
                    <div className="inner">
                        <header className="major">
                            <h1>Expertise</h1>
                        </header>
                        <div className="content">
                            <p>Partner with me</p>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="inner">
                        <h2>Digital Innovation</h2>
                        <p>The question I get most often is "how does this apply to my customers, my business, and my industry". First, it starts off with an assessment. From there, I can tailor a strategic plan whether you're small businesses or part of a large enterprise. There are many options out there to fit your budget and team composition while achieving your goals on time.</p>
                        <h3>Services</h3>
                        <ul>
                            <li>Experimenting with new business models to determine value</li>
                            <li>Technology stack and team composition assessment</li>
                            <li>Transitional and/or experimental strategic planning</li>
                            <li>Reinventing customer experiences with new technologies</li>
                        </ul>
                    </div>
                </section>
                <section>
                    <div className="inner">
                        <h2>Extended Reality (XR): Virtual Reality(VR), Augmented Reality(AR), and 3D Web(Web3D)</h2>
                        <p>These visualization modalities are no different than the web or mobile apps however differ in the way information is delivered.</p>
                        <h3>Services</h3>
                        <ul>
                            <li>Defining and developing a strategy that's unique to your organization's culture, products, and services</li>
                            <li>Content and experience development</li>
                            <li>Scaling options for large enterprises</li>
                            <li>Agency due diligence</li>
                        </ul>
                    </div>
                </section>
                <section id="two">
                    <div className="inner">
                        <h2>Emerging Technology</h2>
                        <p>New technologies are sprouting up everyday. But how does one discover or even make sense of emerging technologies and determine it's potential to solve your problems or create a new product or service?</p>
                        <h3>Services</h3>
                        <ul>
                            <li>Determine how emerging technologies synergize with your business</li>
                            <li>Discovery and research</li>
                            <li>Subject matter expertise outreach and interviewing</li>
                        </ul>
                    </div>
                </section>
                <section>
                    <div className="inner">
                        <h2>Conversion Rate Optimization(CRO)</h2>
                        <p>Make the most of your digital marketing and/or eCommerce investments by implementing a conversion rate optimization program. Not only will you grow your business by doing data driven experimentation, but you will uncover hidden insights about your customers.</p>
                        <h3>Services</h3>
                        <ul>
                            <li>Conversion Rate Optimization program implementation</li>
                            <li>Technology selection and integration</li>
                            <li>Team training and head hunting</li>
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    </Layout>
)

export default services
